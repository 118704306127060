import { initializeClientTelemetry, trackEvent } from './app-insights';
import { decodeTokenAndTrackException } from './get-decoded-button-token';
import {
  AccessButtonEnvironment,
  AccessButtonOptions,
  ApplicationInsightsLoggingData,
  Environment,
} from './types';

declare global {
  interface Window {
    initialiseAccessButton: (options: AccessButtonOptions) => void;
  }
}

const BUTTON_HEADER_NAME = 'access-button';
const NUMBER_OF_RETRIES = 1;
const FETCH_TIMEOUT = 3000;

function handleFetch(
  url: string,
  handleError: () => void,
  retries = NUMBER_OF_RETRIES,
): Promise<Response> {
  return new Promise((resolve, reject) => {
    const controller = new AbortController();
    const { signal } = controller;
    const timeoutId = setTimeout(() => controller.abort('Fetch request timed out'), FETCH_TIMEOUT);
    const options = retries !== 0 ? { signal } : {};

    fetch(url, options)
      .then((response) => {
        clearTimeout(timeoutId);
        if (response.ok) {
          resolve(response);
          return;
        } else if (retries === 0) {
          reject(new Error(response.statusText));
          return;
        }
        handleError();
        handleFetch(url, handleError, --retries).then(resolve, reject);
      })
      .catch((error) => {
        clearTimeout(timeoutId);
        if (retries === 0) {
          reject(error);
          return;
        }
        handleError();
        handleFetch(url, handleError, --retries).then(resolve, reject);
      });
  });
}

async function loadButtonHeader(
  options: AccessButtonOptions,
  loggingData: ApplicationInsightsLoggingData,
) {
  const url = `${getSrcValue(options.environment)}/api/component-version?version=${
    import.meta.env.VITE_ACCESS_BUTTON_LAUNCHER_VERSION
  }`;
  let BUTTON_HEADER_URL: string;
  const trackRetryCount = () => (loggingData.retries += 1);

  await handleFetch(url, trackRetryCount)
    .then(async (response) => {
      let json = await response.json();
      BUTTON_HEADER_URL = json.url;
    })
    .catch((error) => {
      throw error;
    });

  return new Promise((resolve, reject) => {
    if (customElements.get(BUTTON_HEADER_NAME)) {
      resolve({ status: true });
    } else {
      try {
        const scriptEle = document.createElement('script');
        scriptEle.type = 'module';
        scriptEle.async = false;
        scriptEle.src = BUTTON_HEADER_URL;

        scriptEle.addEventListener('load', () => {
          resolve({ status: true });
        });

        scriptEle.addEventListener('error', () => {
          reject({
            status: false,
            message: `Failed to load the script ${BUTTON_HEADER_URL}`,
          });
        });

        document.body.appendChild(scriptEle);
      } catch (error) {
        reject(error);
      }
    }
  });
}

function addHeader(options: AccessButtonOptions) {
  const existingHeader: any = document.querySelector(BUTTON_HEADER_NAME);
  const { environment, ...restOfOptions } = options;
  const mappedOptions = { 
    ...restOfOptions,
    src: getSrcValue(options.environment),
    appInsightsConnectionString: getAppInsightsEnvironment(options.environment)
  };
  if (existingHeader) {
    existingHeader.options = mappedOptions;
    return existingHeader;
  } else {
    const newHeader: any = document.createElement(BUTTON_HEADER_NAME);
    newHeader.options = mappedOptions;

    const parent = options.positions?.parentElement
      ? document.querySelector(options.positions?.parentElement)
      : document.body;
    if (!parent) {
      throw new Error('Unable to find parentElement');
    }
    parent.appendChild(newHeader);
    return newHeader;
  }
}

export async function initialiseAccessButton(options: AccessButtonOptions) {
  initializeClientTelemetry(getAppInsightsEnvironment(options.environment));
  const loggingData: ApplicationInsightsLoggingData = {
    ...options,
    headerIsExtracted: options.positions !== undefined,
    launcherVersion: import.meta.env.VITE_ACCESS_BUTTON_LAUNCHER_VERSION,
    retries: 0,
  };

  return new Promise((resolve, reject) => {
    if (!document.body) {
      const error = new Error('Unable to initialise Access Button. No document body');
      console.warn(error);
      decodeTokenAndTrackException(loggingData, error, 4);
    }

    loadButtonHeader(options, loggingData)
      .then(() => {
        const wc = addHeader(options);
        wc.addEventListener('wsApiReady', (e: CustomEvent) => {
          console.info('Access Button initialised with the following options', options);
          trackEvent('Access Button Initialised', loggingData);
          resolve(wc);
        });
      })
      .catch(async (err) => {
        console.error('Failed to initialise Access Button', err);
        decodeTokenAndTrackException(loggingData, err, 4);
      });
  });
}

if (typeof window !== 'undefined') {
  window.initialiseAccessButton = initialiseAccessButton;
}

const getSrcValue = (environment: Environment) => {
  switch (environment) {
    case 'prod':
      return AccessButtonEnvironment.PRODUCTION;
    case 'prod-anz':
      return AccessButtonEnvironment.PRODANZ;
    case 'demo':
      return AccessButtonEnvironment.DEMO;
    case 'staging':
      return AccessButtonEnvironment.STAGING;
    case 'loadtest' as Environment:
      return AccessButtonEnvironment.LOADTEST;
    case 'preprod':
      return AccessButtonEnvironment.PREPROD;
    default:
      console.info('Unknown environment provided', environment);
      return environment;
  }
};

const getAppInsightsEnvironment = (environment: Environment) => {
  switch (environment) {
    case 'prod':
      return import.meta.env.VITE_PROD_UK_APPLICATIONINSIGHTS_CONNECTION_STRING;
    case 'prod-anz':
      return import.meta.env.VITE_PROD_APAC_APPLICATIONINSIGHTS_CONNECTION_STRING;
    case 'demo':
      return import.meta.env.VITE_DEMO_APPLICATIONINSIGHTS_CONNECTION_STRING;
    case 'staging':
      return import.meta.env.VITE_STAGING_APPLICATIONINSIGHTS_CONNECTION_STRING;
    case 'loadtest' as Environment:
      return import.meta.env.VITE_LOADTEST_APPLICATIONINSIGHTS_CONNECTION_STRING;
    case 'preprod':
      return import.meta.env.VITE_PREPROD_APPLICATIONINSIGHTS_CONNECTION_STRING;
    default:
      return;
  }
};
