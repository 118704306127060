import { AccessButtonOptions, DecodedButtonTokenData, UserData } from '.';
import { trackException } from './app-insights';
import jwtDecode from 'jwt-decode';

export const decodeTokenAndTrackException = async (
  options: AccessButtonOptions,
  error: Error,
  severityLevel: 0 | 1 | 2 | 3 | 4,
) => {
  try {
    const userData = await decodeToken(options);
    trackException(error, severityLevel, mergeOptionsWithUserData(options, userData));
  } catch (decodeError) {
    decodeError instanceof Error
      ? trackException(decodeError, severityLevel, options)
      : trackException(new Error('Unable to decode Button token'), severityLevel, options);
  }
};

async function decodeToken(options: AccessButtonOptions): Promise<UserData> {
  if (typeof options.getToken === 'function') {
    const tokenData = await getDecodedButtonTokenData(options.getToken);
    return tokenData || {};
  }
  return {};
}

const mergeOptionsWithUserData = (options: AccessButtonOptions, userData: UserData = {}) => ({
  ...options,
  userId: userData.userId ?? options.userId,
  organisationId: userData.organisationId ?? options.organisationId,
  applicationId: userData.applicationId ?? options.applicationId,
});

const getDecodedButtonTokenData = async (getToken: () => Promise<Response>): Promise<UserData> => {
  const response = await getToken();
  const token = await response.text();
  const decodedToken: DecodedButtonTokenData = jwtDecode(token);
  return {
    applicationId: decodedToken.context.w_product_id,
    organisationId: decodedToken.context.w_org_id,
    userId: decodedToken.context.w_user_id,
  };
};
