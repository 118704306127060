import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export type UserTrackingProps = {
  userId?: string;
  organisationId?: string;
  applicationId?: string;
  inWorkspace?: boolean;
  view?: string;
  headerIsExtracted?: boolean;
  launcherVersion?: string;
};

let appInsights: ApplicationInsights | undefined;

export function initializeClientTelemetry(connectionString?: string): void {
  try {
    if (!connectionString) return;
    appInsights = new ApplicationInsights({
      config: {
        connectionString,
        extensionConfig: {
          ['AppInsightsCfgSyncPlugin']: {
            cfgUrl: '', // this will block fetching from default cdn
          },
        },
        loggingLevelConsole: 1,
        disableFetchTracking: true,
        disableAjaxTracking: true,
        disableExceptionTracking: true,
      },
    });
    appInsights.loadAppInsights();
  } catch {
    return;
  }
}

export function trackEvent(name: string, properties?: UserTrackingProps): void {
  if (!appInsights) return;

  appInsights.trackEvent({
    name,
    properties,
  });
}

export function trackTrace(
  message: string,
  severityLevel: 0 | 1 | 2 | 3 | 4,
  properties?: UserTrackingProps,
): void {
  if (!appInsights) return;

  appInsights.trackTrace({
    message,
    severityLevel,
    properties,
  });
}

export function trackException(
  exception: Error,
  severityLevel: 0 | 1 | 2 | 3 | 4,
  properties?: UserTrackingProps,
): void {
  if (!appInsights) return;

  appInsights.trackException({
    exception,
    severityLevel,
    properties,
  });
}
