/* THESE TYPES ALSO EXIST IN packages\shared\src\types.ts START*/
/* PLEASE UPDATE BOTH LOCATIONS IF MAKING CHANGES */
/* Types have been duplicated in both location so the Launcher NPM package does not have a direct dependency on packages\shared */

export type AccessButtonOptions = {
  inWorkspace?: boolean;
  overrideNavigationType?: boolean;
  /**
   * @deprecated Use environment instead
   */
  src?: string;
  userId?: string;
  organisationId?: string;
  applicationId?: string;
  positions?: Positions;
  colourScheme?: ColourScheme;
  view?: View;
  closedZindex?: number;
  getToken?: () => Promise<Response>;
  environment: Environment;
  intercomChatEnabled?: boolean;

  /**
   * @deprecated Use positions instead
   */
  position?: DeprecatedPosition;
};

export type ApplicationInsightsLoggingData = AccessButtonOptions & {
  headerIsExtracted: boolean;
  launcherVersion: string;
  retries: number;
};

export type Positions = {
  parentElement?: string;
  desktop?: {
    top?: number;
    left?: number;
  };
  mobile?: {
    left?: number;
    bottom?: number;
    right?: number;
  };
};

export type DeprecatedPosition = {
  top?: number;
  left?: number;
};

export type Position = {
  top?: number;
  left?: number;
  bottom?: number;
  right?: number;
};

export type FlyoutPosition = {
  top: number;
  left: number;
};

export type View = 'desktop' | 'mobile';

export type ColourScheme = 'light' | 'dark';

/* THESE TYPES ALSO EXIST IN packages\shared\src\types.ts END*/

export type Environment = 'prod' | 'staging' | 'demo' | 'preprod' | 'prod-anz';

export enum AccessButtonEnvironment {
  PREPROD = 'https://button-app.preprod.workspace.accessacloud.com',
  LOADTEST = 'https://button-app.loadtest.workspace.accessacloud.com',
  STAGING = 'https://button-app.staging.workspace.accessacloud.com',
  DEMO = 'https://button-app.demo.workspace.accessacloud.com',
  PRODUCTION = 'https://button-app.production.workspace.accessacloud.com',
  PRODANZ = 'https://button-app.production.workspace.access-workspace.com.au',
}

export type DecodedButtonTokenData = {
  context: {
    w_org_id: string;
    w_user_id: string;
    w_product_id?: string;
  };
};

export type UserData = {
  userId?: string;
  organisationId?: string;
  applicationId?: string;
};
